import { useTranslation } from 'react-i18next';
import { defaultLanguage, languages } from '../i18n/config';
import matrice from '../assets/matrices.webp';
import neocognition from '../assets/neocognition.png';

interface HeaderProps {
  hideLanguageButton?: boolean;
  hideTitle?: boolean;
  hideSubtitle?: boolean;
  title: string;
  subtitle?: string;
  testTitle?: string;
}

const Header = ({
  hideLanguageButton,
  hideTitle,
  hideSubtitle,
  title,
  subtitle,
  testTitle
}: HeaderProps) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  }

  const _hideSubtitle = hideSubtitle || subtitle === undefined;

  return (
    <div className="color-light m-5">
      <header className='container m-auto'>
        <div className='flex items-center justify-center w-full 2xl:mb-8'>
          <img className="logo" src={matrice} alt="Chrysippe logo" />
          <img className="logo" src={neocognition} alt="Neocognition logo" />
        </div>
        {hideTitle !== true && (
          <div className={'flex flex-col justify-center items-center w-full ' + (_hideSubtitle ? 'mb-10' : 'mb-5')}>
            <h1 className='text-center text-4xl font-bold'>{title}</h1>
            <h2 className='mt-2 text-2xl font-bold'>{testTitle}</h2>
          </div>
        )}
        {_hideSubtitle !== true && (
          <h2 className='text-center text-2xl mb-10'>{subtitle}</h2>
        )}
      </header>
      {hideLanguageButton !== true && (
        <div className='absolute top-0 right-0'>
          <select
            className="color-light color-bg-darkest rounded-xl p-2 w-auto m-5"
            name="language"
            id="language"
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue={defaultLanguage}
          >
            {languages.map((lng, index) => (
              <option key={index} value={lng}>{lng.toUpperCase()}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default Header;
