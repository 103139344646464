import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const list = t('home.list', { returnObjects: true });

  if (!Array.isArray(list))
    return null;

  return (
    <div className="m-4 text-lg">
      <div className='my-4 text-center italic'>
        <Trans i18nKey={`home.before-${params.type}`} />
      </div>
      {list.map((_: unknown, index: number) => (
        <div key={index} className='my-4'>
          <Trans
            i18nKey={`home.list.${index}`}
            components={{
              underline: <u />,
              glowing: <span className='instruction-color' />,
              listPoint: <span className='text-2xl instruction-color' />,
            }}
          />
        </div>
      ))}
      <div className="float-right">
        <button className="btn btn-primary" onClick={() => navigate('instructions')}>
          {t('home.start')}
        </button>
      </div>
    </div>
  );
}

export default Home;
