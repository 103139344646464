import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import fr from './lang/fr.json';
import en from './lang/en.json';

export const defaultLanguage = 'fr';
export const languages = ['fr', 'en'];

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: defaultLanguage,
        lng: localStorage.getItem('language') ?? defaultLanguage,
        debug: import.meta.env.DEV,
        resources: {
            fr: { translation: fr },
            en: { translation: en },
        },
    });
