import { useTranslation, Trans } from 'react-i18next';
import Demo from '../components/Demo';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Instructions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const list = t('instructions.list', { returnObjects: true });
  const type = useLocation().pathname.split('/')[1]; // pre-test or post-test

  if (!Array.isArray(list))
    return null;

  return (
    <div className='m-4 text-lg'>
      {list.map((_: unknown, index: number) => (
        <div key={index} className='my-4'>
          <Trans
            i18nKey={`instructions.list.${index}`}
            components={{
              underline: <u />,
              glowing: <span className='instruction-color' />,
              listPoint: <span className='text-2xl instruction-color' />,
            }}
          />
        </div>
      ))}
      <Demo type={type} />
      <div className="float-right mb-5">
        <button className="btn btn-primary" onClick={() => navigate('../test', { relative: "path" })}>
          {t('instructions.start')}
        </button>
      </div>
    </div>
  );
}

export default Instructions;
