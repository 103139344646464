import { Trans, useTranslation } from 'react-i18next';
import demoFr from '../assets/demo.fr.gif';

import prefr from '../assets/fr-pre.gif';
import postfr from '../assets/fr-post.gif';
import posten from '../assets/en-post.gif';
import preen from '../assets/en-pre.gif';

import { useEffect, useState } from 'react';
// import demoEn from '../assets/demo.en.gif';

interface DemoProps {
  type: string;
}

const DemoTypedFr = ({type}:DemoProps) => {
    return (
      <>
        {
          type === "pre" ?
          <img className="mx-auto my-5" src={demoFr} alt='demo' /> :
          <img className="mx-auto my-5" src={demoFr} alt='demo' />
         }
      </>
    )

}

const Demo = ({type}:DemoProps) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  useEffect(() => {
    console.log('Current language:', currentLang);
    console.log('Type:', type);
  }, [currentLang]);
  return (
    <>
      <Trans i18nKey={'demo'} />
      {
        type === "pre-test" && currentLang === "fr" ?
        <img className="mx-auto my-5" src={prefr} alt='demo' /> :
        type === "post-test" && currentLang === "fr" ?
        <img className="mx-auto my-5" src={postfr} alt='demo' /> :
        type === "post-test" && currentLang === "en" ?
        <img className="mx-auto my-5" src={posten} alt='demo' /> :
        type === "pre-test" && currentLang === "en" ?
        <img className="mx-auto my-5" src={preen} alt='demo' /> :
        null
      }
    </>
  );
}

export default Demo;
