import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const ValidateTestType = ({ children }: PropsWithChildren) => {
  const params = useParams();

  if (params.type !== 'pre-test' && params.type !== 'post-test')
    return <Navigate to="/pre-test" />;

  return children;
}

export default ValidateTestType;
