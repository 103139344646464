import { BrowserRouter, Navigate, Route, Routes as TreeRoutes } from 'react-router-dom';
import Home from './home';
import Instructions from './instructions';
import Test from './test';
import ValidateTestType from '../components/ValidateTestType';

const Router = BrowserRouter;

export const Routes = () => {
  return (
    <TreeRoutes>
      <Route path="/" element={<Navigate to="/pre-test" />} />
      <Route path="/:type" element={<ValidateTestType children={<Home />} />} />
      <Route path="/:type/instructions" element={<ValidateTestType children={<Instructions />} />} />
      <Route path="/:type/test" element={<ValidateTestType children={<Test />} />} />
      <Route path="*" element={<Navigate to="/pre-test" />} />
    </TreeRoutes>
  );
}

export default Router;
