import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Test = () => {
  const { i18n } = useTranslation();
  const params = useParams();
  console.log(import.meta.env.MODE)
  if (import.meta.env.VITE_TEST_URL === undefined)
    throw new Error('VITE_TEST_URL is not defined in .env file');
  const testUrl = useMemo(() => `${import.meta.env.VITE_TEST_URL}/start.php?lang=${i18n.language}&type=${(params.type ?? 'pre-test').split('-')[0]}`, [])
  return (
    <iframe
      title="Embedded Content"
      name="htmlComp-iframe"
      className='w-full'
      style={{ height: '80vh' }}
      data-src=""
      src={testUrl}
    >
    </iframe>
  );
}

export default Test;
